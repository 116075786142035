import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { InlineWidget } from "react-calendly";
import { Helmet } from "react-helmet";
import ReactPlayer from "react-player";
import Faq from "../components/faq";
import Hero from "../components/hero";
import Layout from "../components/layout";
import Logos from "../components/logos";
import OASection from "../components/our-approach-section";
import yellowOne from "../images/1-yellow.svg";
import yellowTwo from "../images/2-yellow.svg";
import yellowThree from "../images/3-yellow.svg";
import CalendarFormSwitcher from "../components/CalendarFormSwitcher";

const ECommercePage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Hero-Homepage" }) {
				altText
				mediaDetails {
					height
					width
				}
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			sideImg1: wpMediaItem(title: { eq: "Google-image" }) {
				altText
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			gatsbyImg: wpMediaItem(title: { eq: "E-commerce-websites-icon-3" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			shopifyImg: wpMediaItem(title: { eq: "E-commerce-websites-icon-2" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			onlineShopImg: wpMediaItem(title: { eq: "online-shop" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			weReccomentImg: wpMediaItem(title: { eq: "we-reccomend" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			luluImg: wpMediaItem(title: { eq: "lulu-image-new" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			genImg: wpMediaItem(title: { eq: "generates" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			wpSeoPage(title: { eq: "E-Commerce" }) {
				contentForHeadTag {
					content
				}
				seo {
					metaDesc
					title
					twitterImage {
						altText
						mediaDetails {
							height
							width
						}
						link
						gatsbyImage(
							width: 1920
							quality: 75
							formats: [WEBP]
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
					opengraphTitle
					opengraphDescription
				}
			}
			icon: wpMediaItem(title: { eq: "icon" }) {
				link
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const schema = data.wpSeoPage.contentForHeadTag.content;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "E-commerce Websites",
				item: {
					url: `${siteUrl}/e-commerce-websites`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<Helmet>
				{" "}
				<script type="application/ld+json">{schema}</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title={data.wpSeoPage.seo.title}
				description={data.wpSeoPage.seo.metaDesc}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/e-commerce-websites`,
					title: `${data.wpSeoPage.seo.opengraphTitle}`,
					description: `${data.wpSeoPage.seo.opengraphDescription}`,
					images: [
						{
							url: `${data.wpSeoPage.seo.twitterImage.link}`,
							width: `${data.wpSeoPage.seo.twitterImage.mediaDetails.width}`,
							height: `${data.wpSeoPage.seo.twitterImage.mediaDetails.height}`,
							alt: `${data.wpSeoPage.seo.twitterImage.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<section>
					<Hero
						button1="primary-link-dark"
						button1Text="Talk to an expert"
						button1Link="#form"
						button1Var="white"
						button2="white-link-yellow"
						button2Text="Get in touch"
						button2Link="/contact-us"
						button2Var="primary"
						button3={null}
						backgroundImage={data.heroImg.gatsbyImage}
						title={`HEADLESS \nE-COMMERCE \n<span class="text-secondary">AGENCY</span>`}
						description="Building a headless ecommerce solution to help you achieve your business goals."
					/>
				</section>
				{/* <section className="py-5 py-lg-7 bg-light-grey d-none d-lg-block">
					<Container className="p-5 bg-white">
						<Row className="justify-content-center gy-4">
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#intro"
								>
									Introduction
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#shopify"
								>
									Shopify and Gatsby
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#approach"
								>
									Our approach
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#pricing"
								>
									Pricing
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#faq"
								>
									FAQ's
								</a>
							</Col>
						</Row>
					</Container>
				</section> */}
				<section id="intro" className="bg-light-grey py-5 py-lg-7">
					<Container>
						<Row className="align-items-center gx-6 gx-lg-6 gx-xl-7">
							<Col lg={5} className=" ">
								<h2 className="display-5 text-primary mb-5">
									Why go headless?
								</h2>
								<p>
									Don’t rely on an outdated all-in-one ecommerce store, by
									building your online store with the headless approach allows
									you to use a wider range of specialised ecommerce tools and
									services to improve payments, customer experience and
									inventory management. You can{" "}
									<strong>have complete customisation</strong> of the layout of
									your store without the restriction of a template.
								</p>
								<p>
									By separating the front end from the back end, you can design
									your site in the way you want it to look and can integrate any
									back end system that you need to do the job. The headless
									approach ultimately allows you to have greater control over
									your site and product data, while creating a better user
									experience than ever before with{" "}
									<strong>quicker load times</strong>.
								</p>
								<p>
									Get in touch today to discuss your new headless ecommerce
									platform.
								</p>
								<Button
									className=" cta-btn w-100 w-md-auto mt-4 mb-5 mb-lg-0 fs-5 white-link-yellow py-2 px-4 "
									variant="primary"
									href="#form"
								>
									Talk to an expert
								</Button>

								<Button
									className=" w-100 cta-btn w-md-auto fs-5 ms-4 d-none d-xl-inline-block primary-link py-2 px-4 mb-5 mb-lg-0 mt-4 "
									variant="outline-primary"
									as={Link}
									to="/contact-us"
								>
									Get in touch
								</Button>
							</Col>
							<Col lg={7}>
								<ReactPlayer
									playsinline
									loop
									muted
									playing
									className="w-100 h-auto"
									url="https://rjm-digital.rjmdigital.net/wp-content/uploads/2023/10/Headless-Ecommerce.mp4"
								/>
								{/* <div
									style={{
										overflow: "hidden",
										borderRadius: "15px",
										boxShadow: "0px 3px 99px #00000027",
									}}
								>
									<GatsbyImage
										className="w-100"
										image={data.onlineShopImg.gatsbyImage}
										alt={data.onlineShopImg.altText}
									/>
								</div> */}
							</Col>
						</Row>
					</Container>
				</section>
				<section id="benefits" className="py-5 py-lg-7 bg-dark-grey">
					<Container className="mb-8">
						<Row className="justify-content-between align-items-center">
							<Col md={6} lg={8}>
								<h2 className="display-2 ssp-bold text-white mb-lg-5">
									BENEFITS OF
									<span>
										<br />{" "}
										<span className="text-secondary">HEADLESS ECOMMERCE</span>
									</span>
								</h2>
							</Col>
							{/* <Col md={6} lg={4} className="text-end">
								<StaticImage
									src="../../images/Google-Ads-Icon.svg"
									placeholder="blurred"
									quality={100}
									className="w-100"
								/>
							</Col> */}
						</Row>
						<Row className="gx-xl-9 gy-5 justify-content-center">
							<Col className="position-relative" md={6} lg={4}>
								<div
									style={{ top: "-7rem" }}
									className="position-absolute text-center w-100 h-100"
								>
									<img src={yellowOne} className="w-md-40 w-30" />
								</div>
								<div className="px-xl-5 pt-5 position-relative">
									<h3 className="fs-1 text-white">Customisation</h3>
									<p className="text-white">
										have greater control of the design of your ecommerce site
										which will have a significant impact on user engagement.
									</p>
								</div>
							</Col>
							<Col className="position-relative" md={6} lg={4}>
								<div
									style={{ top: "-7rem" }}
									className="position-absolute text-center w-100 h-100"
								>
									<img src={yellowTwo} className="w-md-40 w-30" />
								</div>
								<div className="px-xl-5 pt-5 position-relative">
									<h3 className="fs-1 text-white">Speed</h3>
									<p className="text-white">
										improve the performance with faster page load times and site
										speeds by deploying your website directly to a CDN.
									</p>
								</div>
							</Col>
							<Col className="position-relative " md={6} lg={4}>
								<div
									style={{ top: "-7rem" }}
									className="position-absolute w-100 text-center h-100"
								>
									<img src={yellowThree} className="w-md-40 w-30" />
								</div>
								<div className="px-xl-5 pt-5 position-relative">
									<h3 className="fs-1 text-white">Security</h3>
									<p className="text-white">
										protect your site by splitting your website's front end from
										its back end across different infrastructures reduces the
										impact if something gets hacked.
									</p>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
				<section className="bg-light-grey pb-5 py-lg-7">
					<Container>
						<Row className="align-items-center gx-6 gx-lg-6 gx-xl-7">
							<Col className="d-none d-lg-block" lg={5}>
								<div
									style={{
										overflow: "hidden",
										borderRadius: "15px",
										boxShadow: "0px 3px 99px #00000027",
									}}
								>
									<GatsbyImage
										className="w-100"
										image={data.weReccomentImg.gatsbyImage}
										alt={data.weReccomentImg.altText}
									/>
								</div>
							</Col>
							<Col lg={7} className=" ">
								<h2 className="display-5 mt-5 mt-lg-0 text-primary mb-5">
									Why we trust Shopify
								</h2>
								<p>
									Shopify is a purpose built platform designed specifically for
									ecommerce websites. It offers a user-friendly interface to
									manage all of your orders, products and customers, has strong
									security controls and community developed plug-ins that will
									enable your online shop to adapt as your business grows.
								</p>
								<p>
									With Shopify you can easily manage and track your products,
									process payments securely and utilise a variety of features to
									enhance your customers shopping experience.
								</p>
								<p>
									Talk to an expert to start your headless Shopify journey
									today.
								</p>
								<Button
									className=" cta-btn w-100 w-md-auto mt-4 fs-5 white-link-yellow py-2 px-4 "
									variant="primary"
									href="#form"
								>
									Talk to an expert
								</Button>

								<Button
									style={{ border: "1px solid #023a7f" }}
									className=" w-100 cta-btn w-md-auto fs-5 ms-md-4  primary-link py-2 px-4 mb-5 mb-md-0 mt-4 "
									variant="outline-primary"
									as={Link}
									to="/contact-us"
								>
									Get in touch
								</Button>
							</Col>
						</Row>
					</Container>
				</section>
				<section className="bg-light-grey py-5 py-lg-7">
					<Container>
						<Row className="align-items-center gx-6 gx-lg-6 gx-xl-7">
							<Col lg={6} className=" ">
								<h2 className="display-5 text-primary mb-5">
									Achieve your ecommerce goals more efficiently and effectively
								</h2>
								<p>
									We have experience working with a variety of ecommerce
									platforms and tools, and can help you choose the right ones
									for your business.
								</p>
								<p>
									Our <strong>headless ecommerce developers</strong> has the
									expertise and know-how to provide you with valuable assistance
									in areas like user experience, front-end development and API
									integration, both saving you time and resources. Whilst you
									focus on developing new products for your store, we will take
									care of all the technical aspects of setting up your new
									headless ecommerce site, as well as ensuring your online store
									looks great and performs well on any device your user is
									using.
								</p>
								<p>
									RJM Digital will always be on hand with ongoing support and
									maintenance so you can have confidence that your online shop
									is up-to-date and functioning at its best.
								</p>
							</Col>
							<Col lg={6}>
								<div
									style={{
										overflow: "hidden",
										borderRadius: "15px",
										boxShadow: "0px 3px 99px #00000027",
									}}
								>
									<GatsbyImage
										className="w-100"
										image={data.genImg.gatsbyImage}
										alt={data.genImg.altText}
									/>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
				<section
					id="shopify"
					style={{ overflow: "hidden" }}
					className="bg-light-grey position-relative pt-5 pt-lg-7"
				>
					<div className="position-relative bg-dark-grey">
						<Container className="pb-10 pt-7">
							<Row className="align-items-center mb-5">
								<Col lg={6} className=" ">
									<h2 className=" display-4 text-white ssp-bold mb-4">
										Benefits of
										<br />
										<span className="text-shopify">Shopify</span> &{" "}
										<span className="text-gatsby">GatsbyJS</span>
									</h2>
									<p className="text-white">
										Shopfiy and GatsbyJS are the best tools for developing your
										static ecommerce website, providing you with an easy to use
										system that can expand effortlessly as your sales grow.
									</p>
									<p className="text-white">
										Shopify gives you a brilliant and easy-to-use back-end
										inventory tool with advanced ecommerce features.
									</p>
									<p className="text-white">
										GatsbyJS allows for super fast loading speeds, increased
										security and full customisation of the look and feel of your
										ecommerce site.
									</p>
									<Button
										className={`mt-4 fs-5 w-100  w-md-auto cta-btn py-2 px-4 primary-link-dark `}
										variant="white"
										href="#form"
									>
										Talk to an expert
									</Button>

									<Button
										className=" w-100 cta-btn w-md-auto fs-5 ms-md-4  white-link-yellow py-2 px-4 mb-5 mb-md-0 mt-4 border-white "
										variant="primary"
										as={Link}
										to="/contact-us"
									>
										Get in touch
									</Button>
								</Col>
								<Col lg={6} className=" ">
									<div className="position-absolute">
										<GatsbyImage
											className="w-70"
											image={data.gatsbyImg.gatsbyImage}
											alt={data.gatsbyImg.altText}
										/>
									</div>
									<div
										style={{ top: "8rem", right: "-13rem" }}
										className="position-relative d-none d-md-block "
									>
										{" "}
										<GatsbyImage
											className="w-70"
											image={data.shopifyImg.gatsbyImage}
											alt={data.shopifyImg.altText}
										/>
									</div>
									<div
										style={{ top: "8rem", right: "-7rem" }}
										className="position-relative  d-md-none "
									>
										{" "}
										<GatsbyImage
											className="w-70"
											image={data.shopifyImg.gatsbyImage}
											alt={data.shopifyImg.altText}
										/>
									</div>
								</Col>
							</Row>
						</Container>
					</div>
				</section>
				<div id="approach">
					{" "}
					<OASection
						buttonUrl="#form"
						buttonText="Talk to an expert"
						imgHeight="60rem"
						image={data.luluImg.gatsbyImage}
						imageAlt={data.luluImg.altText}
						launch="After all of our checks and your site works on all devices, RJM Digital team will launch your new headless ecommerce website. Providing you with an online shop that not only has an attractive design but is built using the latest technology."
						build="RJM Digital are well-experienced in ecommerce and will use the best and most suitable technologies for your online shop. You will have access to our test server to monitor the progress of your website and thoroughly test it."
						design="After the brief, we will begin designing your online shop. We will ensure it meets your ecommerce needs, enhances the user experience and is easy to navigate. During the design stage you can make any tweaks and changes until you're completely happy."
						brief="RJM digital will make the process of converting to a headless ecommerce site seamless. Our experienced team will work with you at every step of the way to ensure your website implements the required functionality, is resilient and secure, whilst adhering to your brands guidelines."
					/>
				</div>

				{/* <div id="pricing">
					<PricingSection btnLink="#form" btnText="Talk to an expert" />
				</div> */}
				<Logos />
				{/* <section className="py-5 py-lg-7 bg-light-grey">
					<PortfolioSection page="Home" />
				</section> */}

				<section className="pt-5 pt-lg-7  bg-light-grey">
					<Container>
						<Row id="form">
							<Col className="text-center">
								{" "}
								<h2 className="display-5 text-primary mb-3">
									Talk to an expert
								</h2>
								<p>
									Book a time and day that works for you through our online
									calendar to discuss your e-commerce project.
								</p>
							</Col>
						</Row>
						<Row>
							<CalendarFormSwitcher />
						</Row>
					</Container>
				</section>
				{/* <section className="pt-5 pb-md-5 py-lg-7 bg-light-grey">
          <ReviewSection page="E-commerce" />
        </section> */}
				<div id="faq">
					{" "}
					<Faq page="E-commerce" />
				</div>
				{/* <OSSection
          link3="/branding"
          text3="BRANDING"
          image={data.sideImg1.gatsbyImage}
          imageAlt={data.sideImg1.altText}
        /> */}
			</Layout>
		</>
	);
};

export default ECommercePage;
